import { POST, GET } from "../../utils/request"
import { stringify } from "qs"
import request, { newRequest } from "../../utils/newRequest"

/**
 * 添加门店账号
 */
export const Add_Account = (params) =>
  POST("/data/center/v1/shop/add/account", params)

/**
 * 门店下设备列表接口
 */
// export const Device_List = (params) =>
//   GET(`/data/center/v1/shop/device/list?shopId=${params}`)

/**
 * 门店列表接口
 */
export const Shop_List = (params) => POST("/data/center/v1/shop/list", params)

/**
 * 门店下设备列表
 */
export const Devices_List = (shopId) =>
  GET(`/data/center/v1/device/pull/list?shopId=${shopId}`)

//

export const Devices_ListOne = (params) =>
  POST("/data/center/v1/shop/device/list", params)

/**
 * 产品线类型树
 */
export const Tree_List = () => GET(`/data/center/v1/shop/type/tree/list`)

/**
 * 门店下拉列表
 */
export const Pull_list = (params) =>
  GET(`/data/center/v1/shop/pull/list?${stringify(params)}`)

/**
 * 门店下拉列表(数据权限)
 */
export const New_Pull_list = (params) =>
  POST("/data/center/v1/shop/pull/page", params)

/**
 * 门店下拉列表
 */
export const Pull_Auth_list = (params) =>
  POST("/data/center/v1/shop/pull/page", params)

/**
  查询门店下的账号
 */
export const shop_account_list = (params) =>
  GET(`/data/center/v1/shop/account/list`, { params })

/*
 * 门店体系1.0 添加门店账号
 */
export const add_shop_account = (params) =>
  POST("/data/center/v1/shop/add/account", params)

/*
 * 删除门店下的账号
 */
export const delete_shop_account = (params) =>
  POST("/data/center/v1/shop/delete/account", params)

/*
 * 门店编辑
 */
export const Edit_Shop = (params) => POST("/data/center/v1/shop/edit", params)

/*
 * 编辑设备门店以及称号
 */
export const Edit_Device = (params) =>
  POST("/data/center/v1/device/edit", params)

/**
  门店删除批量
 */
export const shop_batch_delete = (ids) =>
  GET(`/data/center/v1/shop/delete?ids=${ids}`)

/*
 * 新增监控配置
 */
export const Add_Monitor = (params) =>
  POST("/data/center/v1/device/monitor/setting/add", params)

/**
  编辑监控配置
 */
export const Edit_Monito = (params) =>
  POST(`/data/center/v1/device/monitor/setting/edit`, params)

/**
  获取最新监控配置
 */
export const New_Monito = (params) =>
  GET(`/data/center/v1/device/monitor/setting/info`)

/*
 * 监控预览
 */
export const Monitor_Preview = (params) =>
  POST("/data/center/v1/device/monitor/camera/preview", params)

/*
 * 监控回放
 */
export const Monitor_Playback = (params) =>
  POST("/data/center/v1/device/monitor/camera/playback", params)

/*
 * 绑定摄像头
 */
export const Monitor_Bind = (params) =>
  POST("/data/center/v1/device/monitor/camera/bind", params)

/*
 * 获取监控列表
 */
export const Camera_List = (params) =>
  POST("/data/center/v1/device/monitor/camera/list", params)

/**
 * 省市区树
 */
export const Area_Tree = () => GET(`/data/center/v1/index/area/tree`)

/**
 * 门店列表导出
 */
export async function Export_shop_list(params) {
  return newRequest(`/data/center/v1/shop/list/excel`, {
    method: "POST",
    responseType: "blob",
    body: params,
  })
}

/**
 * 查询门店下的账号
 */
export const role_list = (params) =>
  GET(`/data/center/v1/shop/role/list`, { params })

/*
 * 门店维度商品列表
 */
export const goods_shop_list = (params) =>
  GET("/data/center/v1/goods/shop/list", { params })

/*
 * 门店维度商品删除
 */
export const goods_shop_delete = (params) =>
  POST(`/data/center/v1/goods/shop/delete?skuCode`, params)

/*
 * 导入门店商品数据
 */
export const goods_import_excel = (params) => {
  let fd = new FormData()
  for (const key in params) {
    fd.append(key, params[key])
  }
  return POST("/data/center/v1/goods/import/excel", fd, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

/**
 * 当前账号拥有的数据权限
 */
export const Data_Auth = () => GET(`/data/center/v1/user/data/auth`)

/**
 * 项目下所有的设备列表
 */
export const SimplePullList = (params) =>
  GET(`/data/center/v1/device/simple/pull/list`)

/**
 * 获取项目的识别预警规则
 */
export const SetWarning = (params) =>
  GET(`/data/center/v1/setting/rate/warning/setting`)

/**
 * 近七天门店商品识别率预警
 */
export const SetWarningSave = (params) =>
  POST("/data/center/v1/setting/rate/warning/setting/save", params)

/**
 * 门店商品列表
 */
export const shopGoodsList = (params) =>
  POST("/data/center/v1/shop/goods/list", params)

/**
 * 账号权限下，门店下设备列表
 */
export const devicePullList = (params) =>
  POST("/data/center/v1/device/pull/page", params)

/**
 * 获取员工打称记录
 */
export const GetGoodsWeighDetail = (params) =>
  POST("/data/center/v1/record/getGoodsWeighDetail", params)

/**
 * 学习图片共享
 */
export const LearnTaskShare = (params) =>
  POST("/data/center/v1/share/learn/task/pictureShare", params)

/**
 * 获取门店图片url列表
 */
export const ImageUrlList = (batchNo) =>
  GET(`/data/center/v1/share/learn/picture/imageUrlList?batchNo=${batchNo}`)

/**
 * 门店商品,学习图片下发记录
 */
export const LearnPrcieLog = (params) =>
  POST("/data/center/v1/share/learn/picture/log", params)

/**
 * 丢失商品监听设备列表
 */
export const GoodPluginList = (params) =>
  GET(`/data/center/v1/device/orphan/goods/plugin/list?${stringify(params)}`)
