import React, { useEffect, useState, useRef } from "react"
import { Card, Button, Table, Modal, message, Select, Image } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import styles from "./Index.module.css"
import PubInput from "../../../components/PubInput"
import dayjs from "dayjs"
import * as ShopmanageDevice from "../../services/storemanage"
import { PubRangePicker } from "../../../components/PubRangePicker"

const { Option } = Select

function Index(props) {
  let JumpShopId = "" // 上个页面带过来的门店id
  let JumpShopCode = "" // 上个页面带过来的ShopCode
  if (props?.location?.search) {
    if (props?.location?.search?.split("&")) {
      if (props?.location?.search?.split("&")[0]) {
        let NewStr = props?.location?.search?.split("&")[0].split("=")[1]
        JumpShopId = NewStr ? Number(NewStr) : undefined
      }
      if (props?.location?.search?.split("&")[1]) {
        let NewStr = props?.location?.search?.split("&")[1].split("=")[1]
        JumpShopCode = NewStr ? Number(NewStr) : undefined
      }
    }
  }
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [modalLoading, setModalLoading] = useState(false) // 弹窗Loading

  const [PullList, setPullList] = useState([]) // 门店下拉
  const [DeviceList, setDeviceList] = useState([]) // 设备下拉

  // 查询下发图片
  const [modalVisible, setModalVisible] = useState(false)
  const [imgList, setImgList] = useState([]) // 图片列表
  const [imgIndex, setimgIndex] = useState(0)

  const [tableList, setTableList] = useState([]) // 表格数据
  const [total, setTotal] = useState(0) // 表格总数
  const [tableLoading, setTableLoading] = useState(false) // 表格Loading

  // --------------
  const [ParamsInfo, setParamsInfo] = useState({
    pageNum: 1,
    pageSize: 20,
    skuName: undefined,
    shopId: JumpShopId,
    skuCode: JumpShopCode, // 商品名称或PLU或条码
    cdKeyCode: undefined, // 接收设备
    timeArr: [
      dayjs(dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00:00"),
      dayjs(dayjs().format("YYYY-MM-DD") + " 23:59:59"),
    ],
    startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00:00",
    endDate: dayjs().format("YYYY-MM-DD") + " 23:59:59",
  })
  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })
  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])

  // -----------------

  /**
   * 初始化
   */
  useEffect(() => {
    setTimeout(getTableList, 0)
    // 门店列表
    getPullList()
    // 设备列表
    getDevicePullList(ParamsInfo?.shopId)
  }, [])

  /**
   * 动态获取高度
   */
  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 查询表格数据
   */
  const getTableList = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      const params = { ...ParamsInfoRef, timeArr: null }
      setTableLoading(true)
      const res = await ShopmanageDevice.LearnPrcieLog(params)
      if (!res) {
        setTableLoading(false)
        return
      }
      const { code, data, msg } = res
      if (code != 200) {
        message.error(msg)
        setTableLoading(false)
        return
      }
      if (data && data.list) {
        setTableList(data.list)
        setTotal(data.total)
      } else {
        setTableList([])
        setTotal(0)
      }
      setTableLoading(false)
    } catch (error) {
      setTableLoading(false)
      console.log(error)
    }
  }

  /**
   * 查询
   */
  const onSearch = () => {
    setParamsInfo({
      ...ParamsInfo,
      pageNum: 1,
    })
    setTimeout(getTableList, 0)
  }

  /**
   * 重置
   */
  const onReset = () => {
    setParamsInfo({
      pageNum: 1,
      pageSize: 20,
      skuName: undefined,
      shopId: JumpShopId,
      code: JumpShopCode, // 商品名称或PLU或条码
      cdKeyCode: undefined, // 接收设备
      timeArr: [
        dayjs(dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00:00"),
        dayjs(dayjs().format("YYYY-MM-DD") + " 23:59:59"),
      ],
      startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00:00",
      endDate: dayjs().format("YYYY-MM-DD") + " 23:59:59",
    })
    setTimeout(getTableList, 0)
    if (props?.location?.search) {
      getDevicePullList(JumpShopId)
    }
  }

  /**
   * 门店下拉列表
   */
  const getPullList = async () => {
    try {
      const params = { pageNum: 1, pageSize: 9999999 }
      const { code, data, msg } = await ShopmanageDevice.New_Pull_list(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setPullList(data.list || [])
      } else {
        setPullList([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 设备列表
   */
  const getDevicePullList = async (shopId) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 9999999,
        shopId,
      }
      const { code, data, msg } = await ShopmanageDevice.devicePullList(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setDeviceList(data?.list || [])
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取门店图片url列表
   */
  const getLearnPrcieLog = async (batchNo) => {
    try {
      const res = await ShopmanageDevice.ImageUrlList(batchNo)
      if (!res) {
        return
      }
      const { code, data, msg } = res
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data.length > 0) {
        let NewArr = []
        data.map((item, index) => {
          NewArr.push({
            url: item,
            isIssued: false,
            id: index + 1,
          })
        })
        setImgList(NewArr)
      } else {
        setImgList([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 查询下发图片左侧按钮
   */
  const onLeftSeeImg = () => {
    setimgIndex((pre) => {
      return pre * 1 - 1
    })
  }

  /**
   * 查询下发图片右侧按钮
   */
  const onRigSeeImg = () => {
    setimgIndex((pre) => {
      return pre * 1 + 1
    })
  }

  /**
   * 分页
   */
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: total,
      onChange: (current) => {
        setParamsInfo({
          ...ParamsInfo,
          pageNum: current,
        })
        setTimeout(getTableList, 0)
      },
    }
    return paginationProps
  }

  /**
   * 列表展示字段
   */
  const columns = [
    {
      title: "门店名称",
      dataIndex: "shopName",
      key: "shopName",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "同步时间",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "商品名称",
      dataIndex: "skuName",
      ellipsis: { showTitle: true },
      key: "skuName",
      width: 200,
    },
    {
      title: "商品PLU",
      dataIndex: "skuCode",
      ellipsis: { showTitle: true },
      key: "skuCode",
      width: 200,
    },
    {
      title: "下发学习图片数量",
      dataIndex: "size",
      key: "size",
      width: 200,
      ellipsis: { showTitle: true },
    },
    {
      title: "接收设备",
      dataIndex: "scaleNo",
      ellipsis: { showTitle: true },
      key: "scaleNo",
      width: 200,
      render: (info, rowInfo) => {
        return (
          <>
            {info}
            {rowInfo.cdKeyCode ? "秤 - " + rowInfo.cdKeyCode : ""}
          </>
        )
      },
    },
    {
      title: "设备IP",
      dataIndex: "intranetIp",
      ellipsis: { showTitle: true },
      key: "intranetIp",
      width: 200,
    },
    {
      title: "下发方式",
      dataIndex: "shareType",
      ellipsis: { showTitle: true },
      key: "shareType",
      width: 200,
      render: (info) => {
        return info == "0" ? "覆盖" : info == 1 ? "追加" : ""
      },
    },
    {
      title: "是否成功",
      dataIndex: "status",
      ellipsis: { showTitle: true },
      key: "status",
      width: 200,
      render: (info) => {
        return (
          <>
            {info == 1 ? (
              <span style={{ color: "gold" }}>处理中</span>
            ) : info == 2 ? (
              <span style={{ color: "green" }}>成功</span>
            ) : (
              <span style={{ color: "red" }}>失败</span>
            )}
          </>
        )
      },
    },
    {
      title: "操作",
      fixed: "right",
      dataIndex: "",
      ellipsis: { showTitle: true },
      key: "",
      width: 200,
      render: (info, rowInfo) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setimgIndex(0)
                setModalVisible(true)
                getLearnPrcieLog(rowInfo?.batchNo || undefined)
              }}
            >
              查询下发图片
            </Button>
          </>
        )
      },
    },
  ]

  return (
    <div>
      <Card>
        <div className={styles.shop_goods_info_head}>
          <div className={styles.shop_goods_info_head_search}>
            <div>
              日期：
              <PubRangePicker
                disDate={60}
                value={ParamsInfo?.timeArr}
                // disDate={30}
                onChange={(val) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    timeArr: val.TotalTime,
                    startDate: val.startDate,
                    endDate: val.endDate,
                  })
                }}
              />
            </div>
            <div>
              门店：
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                style={{ width: 200 }}
                placeholder="请选择所属门店"
                value={ParamsInfo.shopId || undefined}
                onChange={(e, option) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    shopId: e || undefined,
                    cdKeyCode: undefined,
                  })
                  setDeviceList([])
                  if (e) {
                    getDevicePullList(e)
                  } else {
                    setDeviceList([])
                  }
                }}
              >
                {PullList.map((v) => {
                  return (
                    <Option value={v.shopId} key={v.shopId}>
                      {v.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>
            <div>
              商品名称：
              <PubInput
                width="180px"
                onChange={(val) => {
                  setParamsInfo({ ...ParamsInfo, skuName: val })
                }}
                placeholder="商品名称"
                value={ParamsInfo.skuName}
                allowClear={true}
              />
            </div>
            <div>
              商品PLU：
              <PubInput
                width="180px"
                onChange={(val) => {
                  setParamsInfo({ ...ParamsInfo, skuCode: val })
                }}
                placeholder="商品PLU"
                value={ParamsInfo.skuCode}
                allowClear={true}
              />
            </div>
            <div>
              接收设备：
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择接收设备"
                style={{ width: 200 }}
                allowClear
                value={ParamsInfo.cdKeyCode}
                showSearch
                filterOption={(i, o) =>
                  o.props.children[0].includes(i) ||
                  o.props.children[1].includes(i)
                }
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    cdKeyCode: e || undefined,
                  })
                }}
              >
                {DeviceList.map((v) => (
                  <Option value={v.cdKeyCode} key={v.cdKeyCode}>
                    {v.scaleNo ? v.scaleNo : v.scaleNo}
                    {v.cdKeyCode ? "秤 - " + v.cdKeyCode : ""}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className={styles.shop_goods_info_head_btn}>
            <Button type="primary" onClick={() => onSearch()}>
              查询
            </Button>
            <Button onClick={onReset}>重置</Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: "16px" }}>
        <Table
          columns={columns}
          pagination={onTableChange()}
          dataSource={tableList}
          rowKey="batchNo"
          loading={tableLoading}
          scroll={{ x: "max-content", y: `${WinHeight - 450}px` }}
        />
      </Card>

      {/* 查询下发图片 */}
      <Modal
        title="查询下发图片"
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false)
        }}
        footer={null}
        width={"600px"}
      >
        <div className={styles.modalody}>
          {imgList.length == 0 ? null : imgIndex == 0 ? null : (
            <div
              className={styles.pageModalChildImgBodyDivLeBtn}
              onClick={() => onLeftSeeImg()}
            >
              <LeftOutlined style={{ color: "#2eb998" }} />
            </div>
          )}

          <div className={styles.modalodyImgBody}>
            <div className={styles.modalodyImg}>
              <Image
                src={imgList[imgIndex]?.url}
                width={"100%"}
                height={"100%"}
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className={styles.modalodyTitle}>
              <span style={{ color: "#2eb998", marginRight: "8px" }}>
                {imgIndex + 1}
              </span>
              /<span style={{ marginLeft: "8px" }}>{imgList.length}</span>
            </div>
          </div>
          {imgList.length == 0 ? null : imgIndex ==
            imgList.length - 1 ? null : (
            <div
              className={styles.pageModalChildImgBodyDivRiBtn}
              onClick={() => onRigSeeImg()}
            >
              <RightOutlined style={{ color: "#2eb998" }} />
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default Index
