import {
  Table,
  message,
  Select,
  DatePicker,
  Card,
  Cascader,
  Tooltip,
  Button,
  Modal,
  Input,
} from "antd"
import React, { useEffect, useState, useRef } from "react"
// import { SmileOutlined } from "@ant-design/icons"
import * as echarts from "echarts"
import * as HomeServer from "../../services/home"
import { RightOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import styles from "./Index.module.css"
import { Link, useHistory } from "react-router-dom"
import home1 from "./home1.png"
import home2 from "./home2.png"
import { disabledDateDay } from "../../../utils/util"
import { Decimal } from "decimal.js"

const { Option } = Select
const dateFormat = "YYYY-MM-DD"
const tabs = [
  { name: "日报", value: 1 },
  { name: "周报", value: 2 },
  { name: "月报", value: 3 },
]
/**
 * 首页
 */
function Index() {
  const history = useHistory()
  const [modalLoading, setModalLoading] = useState(false) // 弹窗Loading
  // 预警值设置
  const [openStatus, setOpenStatus] = useState(false)
  const [warnType, setWarnType] = useState(undefined) // 识别预警类型
  const [deviceWarn, setDeviceWarn] = useState(0) // 设备预警值 (默认95)
  const [goodsWarn, setGoodsWarn] = useState(undefined) // 识别商品预警值
  const [NewDeviceWarn, setNewDeviceWarn] = useState(undefined)
  const [NewWarnType, setNewWarnType] = useState(undefined)
  const [NewGoodsWarn, setNewGoodsWarn] = useState(undefined)
  // 门店列表
  const [PullList, setPullList] = useState([]) // 门店列表
  const [SHOPID, setSHOPID] = useState(undefined) // 门店列表选中
  //
  const [tabKey, setTabKey] = useState(1) //tab值，1：日报，2：周报，3：月报
  const [weekList, setWeekList] = useState([]) //周报要展示的列表
  const [monthList, setMonthList] = useState([]) //月要展示的列表
  const [dateValue, setDateValue] = useState("") //日报选择的日期值
  const [weekValue, setWeekValue] = useState([]) //周报选择的日期值
  const [monthValue, setMonthValue] = useState("") //月报选择的日期值
  const [shopSaleTop, setShopSaleTop] = useState({}) //门店识别统计图表数据
  const [shopSaleTab, setShopSaleTab] = useState(1) //称重笔数:1  称重金额:2
  const [goodsSaleTop, setGoodsSaleTop] = useState({}) //商品称重统计图片数据
  const [goodSaleTab, setGoodSaleTab] = useState(1) //称重笔数:1  称重金额:2
  const [goodPriceTab, setGoodPriceTab] = useState(1) //设备识别率 1  商品识别率 2
  const [goodPrice, setGoodPrice] = useState([]) //商品改价记录
  const [shopAbnor, setShopAbnor] = useState([]) //门店异常记录
  //
  const [num, setNum] = useState(1) //  饼形图表-设备使用情况 1？老版：三江
  const [sanJiangInfo, setSanJiangInfo] = useState({}) // 近七天识别率预警详细信息

  //总览
  const [overview, setOverview] = useState({
    shopNum: "", // 门店总数
    todayWeightNum: "", // 今日称重笔数
    yesterdayWeightNum: "", // 昨日称重笔数
    allWeightNum: "", // 累计称重笔数
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = {}

  useEffect(() => {
    onGetWeek() //(调用获取周列表接口方法)
    onGetMonth() //(调用获取月列表接口方法)
    onGetStatis() //(调用首页项目维度统计数据方法)
    getPullList() // 门店列表

    if (localStorage.getItem("SanJiangEarlyWarning")) {
      var myChart = echarts.init(document.getElementById("piehart"))
      myChart.dispose()

      console.log(JSON.parse(localStorage.getItem("SanJiangEarlyWarning")))
      let NewArr = JSON.parse(localStorage.getItem("SanJiangEarlyWarning"))
      let NewBtn = 1
      NewArr.map((v) => {
        if (v.router === "/admin/home/sanjiang") {
          NewBtn = 2
        }
      })
      setNum(NewBtn)
      if (NewBtn == 1) {
        onGetDeviceStatus()
      } else {
        onRecognitionRtearning()
      }
    }

    //获取项目的识别预警规则
    getSetWarning()
  }, [])

  /**
   * 当tab值切换时就会useEffect
   */
  useEffect(() => {
    let DateTime = ""
    // 日报
    if (tabKey == 1) {
      setDateValue(dayjs().format(dateFormat))
      // 统计数据传值
      DateTime = dayjs().format("YYYYMMDD")
    }
    // 周报
    if (tabKey == 2) {
      setWeekValue([
        weekList[0].value,
        weekList[0].children[weekList[0].children.length - 1].value,
      ])
      // 统计数据传值
      DateTime = weekList[0].children[weekList[0].children.length - 1].value
    }
    // 月报
    if (tabKey == 3) {
      setMonthValue(monthList[monthList.length - 1].yearMonth)
      // 统计数据传值
      DateTime = dayjs(monthList[monthList.length - 1].yearMonth).format(
        "YYYYMM"
      )
    }
    onGetMoreData(DateTime) // (调用图表以及各种记录表格数据接口方法)
  }, [tabKey])

  /**
   * 数字三位加个逗号
   */
  const addComma = (num) => {
    return num.toString().replace(/\d+/, function (n) {
      return n.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
    })
  }

  /**
   * 获取项目的识别预警规则
   */
  const getSetWarning = async () => {
    try {
      const res = await HomeServer.SetWarning()
      if (!res) {
        return
      }
      const { code, data, msg } = res
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setWarnType(data?.warningField || undefined)
        setDeviceWarn(
          data?.deviceWarning
            ? new Decimal(data?.deviceWarning).mul(100).toFixed(2)
            : data?.warningField == 1
            ? 95
            : 70 || data?.warningField == 1
            ? 95
            : 70
        )
        setNewWarnType(data?.warningField || undefined)
        setNewDeviceWarn(
          data?.deviceWarning
            ? new Decimal(data?.deviceWarning).mul(100).toFixed(2)
            : data?.warningField == 1
            ? 95
            : 70 || data?.warningField == 1
            ? 95
            : 70
        )
        setGoodsWarn(
          data?.goodsWarning && data?.goodsWarning != 0
            ? new Decimal(data?.goodsWarning).mul(100).toFixed(2)
            : undefined || undefined
        )
        setNewGoodsWarn(
          data?.goodsWarning && data?.goodsWarning != 0
            ? new Decimal(data?.goodsWarning).mul(100).toFixed(2)
            : undefined || undefined
        )
      }
      console.log(data)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 门店下拉列表
   */
  const getPullList = async () => {
    try {
      const params = { pageNum: 1, pageSize: 9999999 }
      const { code, data, msg } = await HomeServer.Pull_list(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setPullList(data.list || [])
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 设备识别率预警值跳转
   */
  const onJumpDevicePage = async (warningQuery) => {
    console.log(goodPriceTab)
    history.push(`/admin/devicemanage/devicelist?warningQuery=${warningQuery}`)
  }
  /**
   * 商品识别率预警值跳转
   */
  const onJumpGoodsPage = async (warningQuery) => {
    console.log(goodPriceTab)
    history.push(
      `/admin/goodsmanage/shopgoods?warningQuery=${warningQuery}&shopId=${SHOPID}`
    )
  }

  /**
   * 获取周列表
   */
  const onGetWeek = async () => {
    try {
      const { code, data, msg } = await HomeServer.LastWeek()
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data.length > 0) {
        data.map((v) => {
          v.label = v.year
          v.value = v.year
          v.children = v.lastWeekRspList
          v.children.map((w) => {
            w.value =
              dayjs(w.weekStartDate).format("YYYYMMDD") +
              "-" +
              dayjs(w.weekEndDate).format("YYYYMMDD")
            w.label =
              w.weekStartDate + "-" + w.weekEndDate + `（第${w.weekOfYear}）周`
          })
        })
      }
      setWeekList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取月列表
   */
  const onGetMonth = async () => {
    try {
      const { code, data, msg } = await HomeServer.LastMonth()
      if (code != 200) {
        message.error(msg)
        return
      }
      setMonthList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 首页项目维度统计数据
   */
  const onGetStatis = async (value) => {
    try {
      const params = {
        date: value,
        dateType: tabKey,
      }
      const { code, data, msg } = await HomeServer.StatisticsData(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setOverview({ ...overview, ...data })
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 封装横向柱状echarts
   */
  const onColumnarChart = (ClassId, Ydata, Xdata, unit) => {
    echarts.init(document.getElementById(ClassId)).dispose()
    var chartDom = document.getElementById(ClassId)
    if (chartDom !== null) {
      var myChart = echarts.init(chartDom)
      var option
      //解决图表不会自适应问题
      const chartObserver = new ResizeObserver(() => {
        myChart.resize()
      })
      chartObserver.observe(chartDom)
      option = {
        tooltip: {
          trigger: "axis",
          textStyle: { fontSize: 12 }, //调节tip文字大小
          backgroundColor: "rgba(255,255,255,0.8)",
          formatter: function (params) {
            // shopSaleTab 称重笔数:1  称重金额:2 总识别率:3
            var relVal = params[0].name
            for (var i = 0, l = params.length; i < l; i++) {
              relVal +=
                "  \n" +
                params[i].marker +
                " : " +
                (unit === "unitG"
                  ? params[i].value + "笔"
                  : unit === "unitY"
                  ? params[i].value > 1000000000
                    ? (params[i].value / 1000000000).toFixed(2) + "kw"
                    : params[i].value > 1000000
                    ? (params[i].value / 1000000).toFixed(2) + "w"
                    : params[i].value > 100000
                    ? (params[i].value / 100000).toFixed(2) + "k"
                    : (params[i].value / 100).toFixed(2) + "元"
                  : unit === "unitZ"
                  ? (params[i].value * 100).toFixed(2) + "%"
                  : "")
            }
            return relVal
          },
        },
        legend: {},
        grid: {
          right: "15%",
          bottom: "-20",
          top: "5%",
          left: 0,
          containLabel: true,
        },
        xAxis: {
          type: "value",
          // boundaryGap: [0, 0.1],
          show: false, // 不显示坐标轴线、坐标轴刻度线和坐标轴上的文字
        },
        yAxis: {
          type: "category",
          inverse: true,
          data: Xdata,
          axisTick: {
            show: false, // 不显示坐标轴刻度线
          },
          axisLine: {
            show: false, // 不显示坐标轴刻度线
          },
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#000",
              fontSize: "15",
              itemSize: "",
            },
            formatter: (value, index) => {
              if (value.length > 6) {
                return value.substring(0, 6) + "..."
              } else {
                return value
              }
            },
          },
        },
        series: [
          {
            // name: "2012",
            type: "bar",
            data: Ydata,
            barWidth: "20px",
            showBackground: true,
            backgroundStyle: {
              color: "#ebf8f3",
              borderRadius: 20,
            },
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "black",
                    fontSize: 12,
                  },
                  formatter: (params) => {
                    return unit === "unitG"
                      ? params.value + "笔"
                      : unit === "unitY"
                      ? params.value > 1000000000
                        ? (params.value / 1000000000).toFixed(2) + "kw"
                        : params.value > 1000000
                        ? (params.value / 1000000).toFixed(2) + "w"
                        : params.value > 100000
                        ? (params.value / 100000).toFixed(2) + "k"
                        : (params.value / 100).toFixed(2) + "元"
                      : unit === "unitZ"
                      ? (params.value * 100).toFixed(2) + "%"
                      : ""
                  },
                },
                barBorderRadius: 20,
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "#38BF87" },
                  { offset: 1, color: "#7EECBF" },
                ]),
              },
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "#38BF87" },
                  { offset: 0.5, color: "#7EECBF" },
                  { offset: 1, color: "#7EECBF" },
                ]),
              },
            },
          },
        ],
      }

      myChart.setOption(option)
    }
  }

  /**
   * 图表以及各种记录表格数据
   * shopSaleTop:门店识别统计图表数据 count：称重笔数； amount：称重金额
   * goodsSaleTop:商品称重统计图片数据
   * shopChangePriceLogTop:门店改价记录数据
   * goodsChangePriceLogTop：商品改价记录数据
   * shopAbnormalLogTop：门店异常记录数据
   */
  const onGetMoreData = async (value) => {
    try {
      const params = {
        date: value,
        dateType: tabKey,
      }
      const { code, msg, data } = await HomeServer.TopData(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      const {
        shopSaleTop,
        goodsSaleTop,
        shopChangePriceLogTop,
        goodsChangePriceLogTop,
        shopAbnormalLogTop,
      } = data
      let shopCountName = [] //门店-称重笔数name
      let shopCountNum = [] //门店-称重金额showNumStr||num
      let goodCountName = [] //商品-称重笔数name
      let goodCountNum = [] //商品-称重金额showNumStr||num
      let unit = "unitG" //单位

      //缓存商品改价记录
      setGoodPrice(goodsChangePriceLogTop)

      //缓存门店异常记录数据
      setShopAbnor(shopAbnormalLogTop)

      // 门店销售图表
      setShopSaleTop(shopSaleTop)
      if (shopSaleTop?.count && shopSaleTop?.count.length > 0) {
        shopSaleTop.count.map((v) => {
          shopCountName.push(v.name)
          shopCountNum.push(v.num)
        })
      }

      // 商品销售图表
      setGoodsSaleTop(goodsSaleTop)
      if (goodsSaleTop?.count && goodsSaleTop?.count.length > 0) {
        goodsSaleTop.count.map((v) => {
          goodCountName.push(v.name)
          goodCountNum.push(v.num)
        })
      }

      //调用封装横向柱状echarts方法
      onColumnarChart("shopChart", shopCountNum, shopCountName, unit)
      //调用封装横向柱状echarts方法
      onColumnarChart("goodsChart", goodCountNum, goodCountName, unit)
    } catch (error) {
      message.error(error)
    }
  }

  /**
   * 门店识别统计图表内  称重笔数1  称重金额2
   */
  const onShopTab = (value) => {
    let shopCountName = [] //称重笔数name
    let shopCountNum = [] //称重金额showNumStr||num
    let shopAmountName = [] //称重金额name
    let shopAmountNum = [] //称重金额num
    let shopAiOkRateName = [] //总识别率name
    let shopAiOkRateNum = [] //总识别率num

    let unit = ""
    // 称重笔数
    if (shopSaleTop?.count && shopSaleTop?.count.length > 0) {
      shopSaleTop.count.map((v) => {
        shopCountName.push(v.name)
        shopCountNum.push(v.num)
      })
    }
    // 称重金额
    if (shopSaleTop?.amount && shopSaleTop?.amount.length > 0) {
      shopSaleTop.amount.map((v) => {
        shopAmountName.push(v.name)
        shopAmountNum.push(v.num)
      })
    }
    // 总识别率
    if (shopSaleTop?.aiOkRate && shopSaleTop?.aiOkRate.length > 0) {
      shopSaleTop.aiOkRate.map((v) => {
        shopAiOkRateName.push(v.name)
        shopAiOkRateNum.push(v.num)
      })
    }

    if (value == 1) {
      unit = "unitG"
      onColumnarChart("shopChart", shopCountNum, shopCountName, unit) //调用封装横向柱状echarts方法
    }
    if (value == 2) {
      unit = "unitY"
      onColumnarChart("shopChart", shopAmountNum, shopAmountName, unit) //调用封装横向柱状echarts方法
    }
    if (value == 3) {
      unit = "unitZ"
      onColumnarChart("shopChart", shopAiOkRateNum, shopAiOkRateName, unit) //调用封装横向柱状echarts方法
    }
    setShopSaleTab(value)
  }

  /**
   * 商品称重统计  称重笔数1  称重金额2
   */
  const onGoodTab = (value) => {
    let goodCountName = [] //商品笔数name
    let goodCountNum = [] //商品金额showNumStr||num
    let goodAmountName = [] //商品金额name
    let goodAmountNum = [] //商品金额num
    let unit = ""
    // 商品笔数
    if (goodsSaleTop?.count && goodsSaleTop?.count.length > 0) {
      goodsSaleTop.count.map((v) => {
        goodCountName.push(v.name)
        goodCountNum.push(v.num)
      })
    }
    // 商品金额
    if (goodsSaleTop?.amount && goodsSaleTop?.amount.length > 0) {
      goodsSaleTop.amount.map((v) => {
        goodAmountName.push(v.name)
        goodAmountNum.push(v.num)
      })
    }

    if (value == 1) {
      unit = "unitG"
      onColumnarChart("goodsChart", goodCountNum, goodCountName, unit) //调用封装横向柱状echarts方法
    }
    if (value == 2) {
      unit = "unitY"
      onColumnarChart("goodsChart", goodAmountNum, goodAmountName, unit) //调用封装横向柱状echarts方法
    }
    setGoodSaleTab(value)
  }

  /**
   * 设备数据统计-设备在线情况 (饼图接口数据)
   */
  const onGetDeviceStatus = async (NewGoodPriceTab, shopId) => {
    // 1：设备识别率 2：商品识别率
    try {
      const { code, data, msg } = await HomeServer.GetDeviceStatus()
      if (code != 200) {
        message.error(msg)
        return
      }
      echarts.init(document.getElementById("piehart")).dispose()
      var chartDom = document.getElementById("piehart")
      if (chartDom !== null) {
        var myChart = echarts.init(chartDom)
        var option
        //解决图表不会自适应问题
        const chartObserver = new ResizeObserver(() => {
          myChart.resize()
        })
        chartObserver.observe(chartDom)
        option = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            orient: "horizontal",
            top: "top",
          },
          graphic: [
            {
              type: "text",
              left: "center",
              top: "43%",
              style: {
                text: `${data?.total || 0}台`,
                textAlign: "center",
                fill: "#000000",
                fontSize: 23,
                fontWeight: 500,
              },
            },
            {
              //环形图中间添加文字
              type: "text", //通过不同top值可以设置上下显示
              left: "center",
              top: "52%",
              style: {
                text: "设备数量",
                textAlign: "center",
                fill: "#999999", //文字的颜色
                fontSize: 14,
                // lineHeight: 16,
              },
            },
          ],
          series: [
            {
              // name: "Access From",
              type: "pie",
              radius: ["40%", "60%"],
              center: ["50%", "50%"],
              data: [
                // { value: data?.total || 0, name: "全部设备" },
                {
                  value: data?.onlineNum || 0,
                  name: "设备在线",
                  itemStyle: { color: "#2EB998" },
                },
                {
                  value: data?.offLineNum || 0,
                  name: "设备离线",
                  itemStyle: { color: "#F9B643" },
                },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              label: {
                normal: {
                  show: true,
                  // position: "inside", // 数值显示在内部
                  formatter: "{b} : {c}%", // 格式化数值百分比输出
                  formatter: (params) => {
                    // return (
                    //   params.name +
                    //   " : " +
                    //   ((params.value / data?.total) * 100).toFixed(2) +
                    //   "%"
                    // )
                    return params.name + " : " + params.value + "台"
                  },
                },
              },
            },
          ],
        }

        myChart.setOption(option)
      }
    } catch (error) {
      console.log(error)
      message.error(error)
    }
  }

  /**
   * 近七天识别率预警
   */
  const onRecognitionRtearning = async (NewGoodPriceTab, shopId) => {
    // 1：设备识别率 2：商品识别率
    let TabType = NewGoodPriceTab ? NewGoodPriceTab : goodPriceTab
    console.log("识别率=>>", TabType)
    try {
      let res
      if (TabType == 1) {
        res = await HomeServer.DeviceWarn()
      } else {
        res = await HomeServer.GoodsWarn(shopId ? shopId : SHOPID)
      }
      // console.log(res)
      if (!res) {
        return
      }
      const { code, data, msg } = res
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data.deviceWarning) {
        data.deviceWarning = new Decimal(data?.deviceWarning)
          .mul(100)
          .toString()
      } else {
        data.deviceWarning = 0
      }
      if (data.goodsWarning) {
        data.goodsWarning = new Decimal(data?.goodsWarning).mul(100).toString()
      } else {
        data.goodsWarning = 0
      }
      setSanJiangInfo(data)
      echarts.init(document.getElementById("piehart")).dispose()
      var chartDom = document.getElementById("piehart")
      if (chartDom !== null) {
        var myChart = echarts.init(chartDom)
        var option
        //解决图表不会自适应问题
        const chartObserver = new ResizeObserver(() => {
          myChart.resize()
        })
        chartObserver.observe(chartDom)
        option = {
          tooltip: {
            trigger: "item",
          },
          series: [
            {
              // name: "Access From",
              type: "pie",
              radius: ["40%", "50%"],
              center: ["50%", "50%"],
              data: [
                // { value: data?.total || 0, name: "全部设备" },
                {
                  value: data?.upWarningNum || 0,
                  name: "高于预警值",
                  itemStyle: { color: "#2EB998" },
                },
                {
                  value: data?.downWarningNum || 0,
                  name: "低于预警值",
                  itemStyle: { color: "#F9B643" },
                },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              label: {
                normal: {
                  show: true,
                  formatter: "{b} : {c}%", // 格式化数值百分比输出
                  formatter: (params) => {
                    return params.name + " : " + params.value + "个"
                  },
                },
              },
            },
          ],
        }

        myChart.setOption(option)
      }
    } catch (error) {
      console.log(error)
      message.error(error)
    }
  }

  /**
   * 商品改价记录
   */
  const goodColumns = [
    {
      title: "商品名称",
      dataIndex: "name",
      key: "name",
      ellipsis: { showTitle: true },
    },
    {
      title: "改价次数",
      dataIndex: "priceCount",
      key: "priceCount",
      ellipsis: { showTitle: true },

      render: (info, rowInfo) => {
        return (
          // <Link
          //   to={{
          //     pathname: "/admin/lossmanage/updateroad",
          //     state: {
          //       skuCode: rowInfo?.name,
          //       tabKey: tabKey,
          //       specific_time:
          //         tabKey == 1
          //           ? dateValue
          //           : tabKey == 2
          //           ? weekValue
          //           : tabKey == 3
          //           ? monthValue
          //           : "",
          //     },
          //   }}
          // >
          //   <span style={{ color: "#2EB998" }}>{info} 次</span>
          // </Link>
          <>{info ? info : 0} 次</>
        )
      },
    },
  ]
  /**
   * 门店异常记录
   */
  const goodRoadColumns = [
    {
      title: "门店名称",
      dataIndex: "name",
      key: "name",
      ellipsis: { showTitle: true },
    },
    {
      title: "监控改价出售次数",
      dataIndex: "priceCount",
      key: "priceCount",
      ellipsis: { showTitle: true },

      render: (info, rowInfo) => {
        return (
          // <Link
          //   to={{
          //     pathname: "/admin/lossmanage/updateroad",
          //     state: {
          //       shopId: rowInfo?.shopId,
          //       tabKey: tabKey,
          //       specific_time:
          //         tabKey == 1
          //           ? dateValue
          //           : tabKey == 2
          //           ? weekValue
          //           : tabKey == 3
          //           ? monthValue
          //           : "",
          //     },
          //   }}
          // >
          //   <span style={{ color: "#2EB998" }}>{info ? info : 0} 次</span>
          // </Link>
          <>{info ? info : 0} 次</>
        )
      },
    },
    {
      title: "商品损耗次数",
      dataIndex: "abnormalNum",
      key: "abnormalNum",
      ellipsis: { showTitle: true },

      render: (info, rowInfo) => {
        return (
          // <Link
          //   to={{
          //     pathname: "/admin/datareport/weightrecord",
          //     state: {
          //       shopId: rowInfo?.shopId,
          //       tabKey: tabKey,
          //       specific_time:
          //         tabKey == 1
          //           ? dateValue
          //           : tabKey == 2
          //           ? weekValue
          //           : tabKey == 3
          //           ? monthValue
          //           : "",
          //     },
          //   }}
          // >
          //   <span style={{ color: "#2EB998" }}>{info ? info : 0} 次</span>
          // </Link>
          <>{info ? info : 0} 次</>
        )
      },
    },
    // {
    //   title: "异常称重",
    //   dataIndex: "weightCount",
    //   key: "weightCount",
    //   ellipsis: { showTitle: true },
    //   //
    //   render: (info, rowInfo) => {
    //     return (
    //       <Link
    //         to={{
    //           pathname: "/admin/datareport/weightrecord",
    //           state: {
    //             shopId: rowInfo?.shopId,
    //             tabKey: tabKey,
    //             specific_time:
    //               tabKey == 1
    //                 ? dateValue
    //                 : tabKey == 2
    //                 ? weekValue
    //                 : tabKey == 3
    //                 ? monthValue
    //                 : "",
    //           },
    //         }}
    //       >
    //         <span style={{ color: "#2EB998" }}>{info} </span>
    //       </Link>
    //     )
    //   },
    // },
  ]

  //详情
  const onJump = (value) => {
    // value:1 跳门店
    // value:2 跳商品
    // 3:改价记录
    // 4:告警记录
    return (
      <Link
        to={{
          pathname:
            // value == 1 ? `/admin/home/shopdetail` : "/admin/home/gooddetail",
            value == 1
              ? `/admin/home/shopdetail`
              : value == 2
              ? "/admin/home/gooddetail"
              : value == 3
              ? "/admin/lossmanage/updateroad"
              : value == 4
              ? "/admin/lossmanage/policeroad"
              : "",
          state: {
            tabKey: tabKey,
            specific_time:
              tabKey == 1
                ? dateValue
                : tabKey == 2
                ? weekValue
                : tabKey == 3
                ? monthValue
                : "",
          },
        }}
      >
        <div className={styles.home_child_detail}>
          <span className={styles.home_detail_title}>查看详情</span>{" "}
          <RightOutlined />
        </div>
      </Link>
    )
  }

  /**
   * 预警设置保存按钮
   */
  const onSave = async () => {
    try {
      const params = {
        warningField: warnType,
        deviceWarning:
          deviceWarn && deviceWarn > 0
            ? (deviceWarn / 100).toFixed(4)
            : undefined,
        goodsWarning:
          goodsWarn && goodsWarn > 0 ? (goodsWarn / 100).toFixed(4) : undefined,
      }
      if (!params.warningField) {
        return message.error("请选择预警类型")
      }
      if (!params.deviceWarning) {
        return message.error("请输入设备预警值")
      }
      console.log(params)
      setModalLoading(true)
      const res = await HomeServer.SetWarningSave(params)
      if (!res) {
        setModalLoading(false)
        return
      }
      const { code, data, msg } = res
      if (code != 200) {
        message.error(msg)
        setModalLoading(false)
        return
      }
      if (data) {
        message.success("预警设置成功")
        setModalLoading(false)
        setOpenStatus(false)
        onRecognitionRtearning()
        //获取项目的识别预警规则
        getSetWarning()
      }
    } catch (error) {
      setModalLoading(false)
      console.log(error)
    }
  }
  return (
    // 首页布局
    <div className={styles.home_page}>
      {/* 总览 */}
      <Card title="总览">
        <div className={styles.home_page_overview}>
          {/* 门店总数 */}
          <div className={styles.home_page_overview_child}>
            <div className={styles.home_page_overview_child_body}>
              <div className={styles.home_page_overview_child_body_left}>
                <div className={styles.child_body_left_shoptotal}>
                  {" "}
                  门店总数
                </div>
                <Tooltip title={overview.shopNum || 0} placement="left">
                  <div className={styles.child_body_left_shopNum}>
                    {overview.shopNum || 0}
                    <span>家</span>
                  </div>
                </Tooltip>
                <div
                  className={styles.child_body_left_shopDetail}
                  onClick={() => {
                    history.push(`/admin/devicemanage/shopmanage`)
                  }}
                >
                  查看详情
                  <RightOutlined className={styles.child_body_left_shopIcon} />
                </div>
              </div>
              <div className={styles.home_page_overview_child_body_right}>
                <img src={home1} alt="" />
              </div>
            </div>
          </div>
          {/* 今日称重笔数 */}
          <div className={styles.home_page_overview_child}>
            <div className={styles.home_page_overview_child_body}>
              <div className={styles.home_page_overview_child_body_left}>
                <div className={styles.child_body_left_shoptotal}>
                  {" "}
                  今日称重笔数
                </div>
                <Tooltip title={overview.todayWeightNum || 0} placement="left">
                  <div className={styles.child_body_left_shopNum}>
                    {overview.todayWeightNum || 0}
                    <span>笔</span>
                  </div>
                </Tooltip>
                <Link
                  to={{
                    pathname: "/admin/datareport/weightrecord",
                    state: {
                      tabKey: tabKey,
                      specific_time: dayjs().format("YYYY-MM-DD"), // 当天
                    },
                  }}
                >
                  <div className={styles.child_body_left_shopDetail}>
                    查看详情
                    <RightOutlined
                      className={styles.child_body_left_shopIcon}
                    />
                  </div>
                </Link>
              </div>
              <div className={styles.home_page_overview_child_body_right}>
                <img src={home2} alt="" />
              </div>
            </div>
          </div>
          {/* 昨日称重笔数 */}
          <div className={styles.home_page_overview_child}>
            <div className={styles.home_page_overview_child_body}>
              <div className={styles.home_page_overview_child_body_left}>
                <div className={styles.child_body_left_shoptotal}>
                  {" "}
                  昨日称重笔数
                </div>
                <Tooltip
                  title={overview.yesterdayWeightNum || 0}
                  placement="left"
                >
                  <div className={styles.child_body_left_shopNum}>
                    {overview.yesterdayWeightNum || 0}
                    <span>笔</span>
                  </div>
                </Tooltip>
                <Link
                  to={{
                    pathname: "/admin/datareport/weightrecord",
                    state: {
                      tabKey: tabKey,
                      specific_time: dayjs()
                        .subtract(1, "day")
                        .format("YYYY-MM-DD"), //昨天
                    },
                  }}
                >
                  <div className={styles.child_body_left_shopDetail}>
                    查看详情
                    <RightOutlined
                      className={styles.child_body_left_shopIcon}
                    />
                  </div>
                </Link>
              </div>
              <div className={styles.home_page_overview_child_body_right}>
                <img src={home2} alt="" />
              </div>
            </div>
          </div>

          <div className={styles.home_page_overview_child}>
            <div className={styles.home_page_overview_child_body}>
              <div className={styles.home_page_overview_child_body_left}>
                <div className={styles.child_body_left_shoptotal}>
                  {" "}
                  累计称重笔数
                </div>
                <Tooltip title={overview.allWeightNum || 0} placement="left">
                  <div className={styles.child_body_left_shopNum}>
                    {overview.allWeightNum || 0}
                    <span>笔</span>
                  </div>
                </Tooltip>
                <div className={styles.child_body_left_shopDetail}></div>
              </div>
              <div className={styles.home_page_overview_child_body_right}>
                <img src={home2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Card>

      {/* 底部主体内容 */}
      <Card style={{ marginTop: "16px" }}>
        {/* 日期选择 */}
        <div className={styles.home_page_date}>
          <div className={styles.home_page_date_tab}>
            {tabs.map((v, i) => {
              return (
                <div
                  key={i}
                  className={
                    tabKey == v.value ? styles.home_tab_check : styles.home_tab
                  }
                  onClick={() => {
                    setTabKey(v.value)
                    setShopSaleTab(1) // 称重笔数:1  称重金额:2
                    setGoodSaleTab(1) // 称重笔数:1  称重金额:2
                  }}
                >
                  {v.name}
                </div>
              )
            })}
          </div>
          {tabKey == 1 ? (
            <DatePicker
              style={{ width: 200 }}
              onChange={(e) => {
                setDateValue(dayjs(e).format(dateFormat))
                onGetMoreData(dayjs(e).format("YYYYMMDD"))
                setShopSaleTab(1) // 称重笔数:1  称重金额:2
                setGoodSaleTab(1) // 称重笔数:1  称重金额:2
              }}
              value={dayjs(dateValue)}
              allowClear={false}
              disabledDate={disabledDateDay}
            />
          ) : null}

          {/* 周报选择框 */}
          {tabKey == 2 ? (
            <Cascader
              style={{ width: "auto" }}
              options={weekList}
              value={weekValue}
              onChange={(e) => {
                setWeekValue(e)
                onGetMoreData(e[1])
                setShopSaleTab(1) // 称重笔数:1  称重金额:2
                setGoodSaleTab(1) // 称重笔数:1  称重金额:2
              }}
              placeholder="请选择周"
            />
          ) : null}

          {/* 月报选择框 */}
          {tabKey == 3 ? (
            <Select
              style={{ width: 200 }}
              value={monthValue}
              onChange={(e) => {
                setMonthValue(e)
                onGetMoreData(dayjs(e).format("YYYYMM"))
                setShopSaleTab(1) // 称重笔数:1  称重金额:2
                setGoodSaleTab(1) // 称重笔数:1  称重金额:2
              }}
              placeholder="请选择月"
            >
              {monthList.map((v) => {
                return (
                  <Option key={v.yearMonth} value={v.yearMonth}>
                    {v.yearMonth}
                  </Option>
                )
              })}
            </Select>
          ) : null}
        </div>

        {/* 图表展示 */}
        <div className={styles.home_body_info}>
          {/* 横向柱状图 - 门店识别统计   shopSaleTop*/}
          <Card
            className={styles.home_card_child}
            // hoverable
            title="门店识别统计"
            extra={onJump(1)}
          >
            <div className={styles.home_card_tabs}>
              <span
                style={{ color: shopSaleTab == 1 ? "#2EB998" : "" }}
                onClick={() => {
                  onShopTab(1)
                }}
              >
                称重笔数
              </span>
              <span
                style={{ color: shopSaleTab == 2 ? "#2EB998" : "" }}
                onClick={() => {
                  onShopTab(2)
                }}
              >
                称重金额
              </span>
              <span
                style={{ color: shopSaleTab == 3 ? "#2EB998" : "" }}
                onClick={() => {
                  onShopTab(3)
                }}
              >
                总识别率
              </span>
            </div>
            <div
              id="shopChart"
              className={styles.home_card_piehart}
              style={{ height: "380px" }}
            />
          </Card>
          {/* 横向柱状图 - 商品称重统计 */}
          <Card
            className={styles.home_card_child}
            // hoverable
            title="商品称重统计"
            extra={onJump(2)}
          >
            <div className={styles.home_card_tabs}>
              <span
                style={{ color: goodSaleTab == 1 ? "#2EB998" : "" }}
                onClick={() => {
                  onGoodTab(1)
                }}
              >
                称重笔数
              </span>
              <span
                style={{ color: goodSaleTab == 2 ? "#2EB998" : "" }}
                onClick={() => {
                  onGoodTab(2)
                }}
              >
                称重金额
              </span>
            </div>
            <div
              id="goodsChart"
              className={styles.home_card_piehart}
              style={{ height: "380px" }}
            ></div>
          </Card>
          {/* 饼形图表-设备使用情况（实时数据） */}
          {num == 1 ? (
            <Card
              className={styles.home_card_child}
              // hoverable
              title="设备使用情况（实时数据）"
              extra={
                <Link
                  to={{
                    pathname: "/admin/devicemanage/devicelist",
                  }}
                >
                  <div className={styles.home_child_detail}>
                    <span className={styles.home_detail_title}>查看详情</span>{" "}
                    <RightOutlined />
                  </div>
                </Link>
              }
            >
              <div
                id="piehart"
                className={styles.home_card_piehart}
                style={{ height: "380px" }}
              ></div>
            </Card>
          ) : (
            <Card
              className={styles.home_card_child}
              // hoverable
              title="近七天识别率预警"
              extra={
                <Button
                  onClick={() => {
                    //获取项目的识别预警规则
                    getSetWarning()
                    //
                    setModalLoading(false)
                    setOpenStatus(true)
                  }}
                >
                  预警值设置
                </Button>
              }
            >
              <div className={styles.piehart_card}>
                <div className={styles.piehart_echart}>
                  <div className={styles.piehart_echartbtn}>
                    <span
                      style={{ color: goodPriceTab == 1 ? "#2EB998" : "" }}
                      onClick={() => {
                        var myChart = echarts.init(
                          document.getElementById("piehart")
                        )
                        myChart.dispose()

                        setSHOPID(undefined)
                        setSanJiangInfo({})
                        setGoodPriceTab(1)
                        onRecognitionRtearning(1) //(调用饼图接口方法)
                      }}
                    >
                      设备识别率
                    </span>
                    {!NewGoodsWarn || NewGoodsWarn == 0 ? null : (
                      <span
                        style={{ color: goodPriceTab == 2 ? "#2EB998" : "" }}
                        onClick={() => {
                          var myChart = echarts.init(
                            document.getElementById("piehart")
                          )
                          myChart.dispose()
                          setGoodPriceTab(2)
                          setSanJiangInfo({})
                          if (PullList?.length > 0) {
                            setSHOPID(PullList[0].shopId)
                            onRecognitionRtearning(2, PullList[0].shopId) //(调用饼图接口方法)
                          } else {
                            setSHOPID(undefined)
                          }
                          console.log(PullList)
                        }}
                      >
                        商品识别率
                      </span>
                    )}
                  </div>
                  <div
                    id="piehart"
                    style={{ height: "380px" }}
                    className={styles.piehart_body}
                  ></div>
                </div>

                <div className={styles.piehart_info}>
                  <div className={styles.piehart_info_slect}>
                    {goodPriceTab == 2 ? (
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        style={{ width: "100%" }}
                        showSearch
                        filterOption={(i, o) => o.props.children.includes(i)}
                        placeholder="请选择所属门店"
                        value={SHOPID || undefined}
                        onChange={(e) => {
                          if (e) {
                            setSHOPID(e)
                            onRecognitionRtearning(2, e) //(调用饼图接口方法)
                          } else {
                            setSanJiangInfo({})
                            setSHOPID(undefined)
                            var myChart = echarts.init(
                              document.getElementById("piehart")
                            )
                            myChart.dispose()
                          }
                        }}
                      >
                        {PullList.map((v) => {
                          return (
                            <Option value={v.shopId} key={v.shopId}>
                              {v.shopName}
                            </Option>
                          )
                        })}
                      </Select>
                    ) : null}
                  </div>
                  <div className={styles.piehart_info_Info}>
                    <div>
                      {goodPriceTab == 1
                        ? `设备总数：${sanJiangInfo?.total || 0}（${
                            sanJiangInfo?.offLineNum || 0
                          }台离线）`
                        : `最近7天有称重记录商品：${
                            sanJiangInfo?.total || 0
                          }个`}
                    </div>
                    <div>
                      {goodPriceTab == 1
                        ? warnType == 1
                          ? "设备总识别率预警值："
                          : warnType == 2
                          ? "设备首位识别率预警值："
                          : ""
                        : warnType == 1
                        ? "商品总识别率预警值："
                        : warnType == 2
                        ? "商品首位识别率预警值："
                        : ""}
                      {goodPriceTab == 1
                        ? sanJiangInfo?.deviceWarning
                        : sanJiangInfo?.goodsWarning}
                      %
                    </div>
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div
                        className={styles.piehart_infospan}
                        style={{ background: "#EFB91C" }}
                      ></div>
                      低于预警值
                      <a
                        onClick={() => {
                          if (goodPriceTab == 1) {
                            onJumpDevicePage(2)
                          } else {
                            onJumpGoodsPage(2)
                          }
                        }}
                      >
                        点击查看
                      </a>
                    </div>
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div
                        className={styles.piehart_infospan}
                        style={{ background: "#2eb998" }}
                      ></div>
                      高于预警值
                      <a
                        onClick={() => {
                          if (goodPriceTab == 1) {
                            onJumpDevicePage(1)
                          } else {
                            onJumpGoodsPage(1)
                          }
                        }}
                      >
                        点击查看
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          )}
        </div>

        {/* 首页 - 表格展示 */}
        <div className={styles.home_body_info}>
          <Card
            className={styles.home_card_child_table}
            // hoverable
            title="门店异常记录"
            extra={onJump(4)}
          >
            <Table
              dataSource={shopAbnor}
              columns={goodRoadColumns}
              pagination={false}
              rowKey="shopId"
            />
          </Card>
          <Card
            className={styles.home_card_child_table}
            // hoverable
            title="商品改价记录"
            extra={onJump(3)}
          >
            <Table
              dataSource={goodPrice}
              columns={goodColumns}
              pagination={false}
              rowKey="skuCode"
            />
          </Card>
        </div>
      </Card>

      {/* 首页 - 数据卡片展示 */}
      {/* <div className="home_body_info">
        <Card
          className="home_info_child"
          // hoverable
          title="称重笔数"
          extra={
            <Link
              to={{
                pathname: "/admin/datareport/weightrecord",
                state: {
                  tabKey: tabKey,
                  specific_time:
                    tabKey == 1
                      ? dateValue
                      : tabKey == 2
                      ? weekValue
                      : tabKey == 3
                      ? monthValue
                      : "",
                },
              }}
            >
              <div className="home_child_detail">
                <span className="home_detail_title">详情</span>{" "}
                <RightCircleOutlined />
              </div>
            </Link>
          }
        >
          <div>
            <span className="home_info_child_span">
              {num?.count ? num?.count : 0}
            </span>
            <span>笔</span>
          </div>
        </Card>
        <Card
          className="home_info_child"
          // hoverable
          title="称重金额"
          extra={
            <Link
              to={{
                pathname: "/admin/datareport/weightrecord",
                state: {
                  tabKey: tabKey,
                  specific_time:
                    tabKey == 1
                      ? dateValue
                      : tabKey == 2
                      ? weekValue
                      : tabKey == 3
                      ? monthValue
                      : "",
                },
              }}
            >
              <div className="home_child_detail">
                <span className="home_detail_title">详情</span>{" "}
                <RightCircleOutlined />
              </div>
            </Link>
          }
        >
          <span className="home_info_child_span">{num?.amount || 0}</span>
          <span>元</span>
        </Card>
        <Card
          className="home_info_child"
          // hoverable
          title="异常称重笔数"
          extra={
            <Link
              to={{
                pathname: "/admin/lossmanage/policeroad",
                state: {
                  type: "2",
                  tabKey: tabKey,
                  specific_time:
                    tabKey == 1
                      ? dateValue
                      : tabKey == 2
                      ? weekValue
                      : tabKey == 3
                      ? monthValue
                      : "",
                },
              }}
            >
              <div className="home_child_detail">
                <span className="home_detail_title">详情</span>{" "}
                <RightCircleOutlined />
              </div>
            </Link>
          }
        >
          <span className="home_info_child_span">
            {num?.abnormalWeighNum || 0}
          </span>
          <span>笔</span>
        </Card>
        <Card
          className="home_info_child"
          // hoverable
          title="监控改价出售次数"
          extra={
            <Link
              to={{
                pathname: "/admin/lossmanage/policeroad",
                state: {
                  type: "1",
                  tabKey: tabKey,
                  specific_time:
                    tabKey == 1
                      ? dateValue
                      : tabKey == 2
                      ? weekValue
                      : tabKey == 3
                      ? monthValue
                      : "",
                },
              }}
            >
              <div className="home_child_detail">
                <span className="home_detail_title">详情</span>{" "}
                <RightCircleOutlined />
              </div>
            </Link>
          }
        >
          <span className="home_info_child_span">
            {num?.abnormalPriceCount || 0}
          </span>
          <span>次</span>
        </Card>
      </div> */}

      {/* 预警设置 */}
      <Modal
        title="预警设置"
        keyboard
        maskClosable
        open={openStatus}
        onCancel={() => {
          setOpenStatus(false)
          //获取项目的识别预警规则
          getSetWarning()
        }}
        footer={[
          <Button
            onClick={() => {
              setOpenStatus(false)
              //获取项目的识别预警规则
              getSetWarning()
            }}
          >
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={onSave}
            loading={modalLoading}
          >
            保存
          </Button>,
        ]}
      >
        <div>
          <div className={styles.pageModalHead}>
            <div style={{ padding: "10px" }}>
              设置后，若设备或商品近7天的平均识别率低于预警值，则将生成对应的统计报告
            </div>
          </div>
          <div className={styles.pageModalBody}>
            <div className={styles.pageModalBoody}>
              <div className={styles.pageModalBoodyTitle}>识别预警类型：</div>
              <Select
                style={{ width: 200 }}
                value={warnType}
                onChange={(e) => {
                  if (e == 1 && e != NewWarnType) {
                    setDeviceWarn(95)
                  }
                  if (e == 2 && e != NewWarnType) {
                    setDeviceWarn(70)
                  }
                  if (e == NewWarnType) {
                    setDeviceWarn(NewDeviceWarn)
                  }
                  setWarnType(e)
                }}
                placeholder="请选择"
              >
                <Option key={1} value={1}>
                  总识别率
                </Option>
                <Option key={2} value={2}>
                  首位识别率
                </Option>
              </Select>
            </div>

            <div className={styles.pageModalBoody}>
              <div className={styles.pageModalBoodyTitle}>
                <span style={{ color: "#F56C6C", marginRight: "3px" }}>*</span>
                设备预警值：
              </div>
              <Input
                style={{ width: 200 }}
                placeholder="请输入设备预警值"
                addonAfter="%"
                value={deviceWarn}
                // maxLength={2}
                onChange={(e) => {
                  let value = e.target.value

                  // 去除非数字、小数点以外的字符
                  value = value.replace(/[^\d.]/g, "")

                  // 限制只能有一个小数点
                  if (value.split(".").length > 2) {
                    value = value.slice(0, -1)
                  }

                  // 如果是小数，确保保留两位小数
                  if (value.indexOf(".") !== -1) {
                    const [integerPart, decimalPart] = value.split(".")
                    value = `${integerPart}.${decimalPart.slice(0, 2)}`
                  }

                  // 限制不能大于100
                  if (value && parseFloat(value) > 100) {
                    value = "100"
                  }

                  setDeviceWarn(value)
                }}
              />
            </div>

            <div className={styles.pageModalBoody}>
              <div className={styles.pageModalBoodyTitle}>商品预警值：</div>
              <Input
                style={{ width: 200 }}
                placeholder="请输入商品预警值"
                addonAfter="%"
                // maxLength={2}
                value={goodsWarn}
                onChange={(e) => {
                  if (!e || e.target.value == 0) {
                    setGoodPriceTab(1)
                  }
                  e.target.value.replace(/[^\d]/g, "")
                  {
                    let value = e.target.value

                    // 去除非数字、小数点以外的字符
                    value = value.replace(/[^\d.]/g, "")

                    // 限制只能有一个小数点
                    if (value.split(".").length > 2) {
                      value = value.slice(0, -1)
                    }

                    // 如果是小数，确保保留两位小数
                    if (value.indexOf(".") !== -1) {
                      const [integerPart, decimalPart] = value.split(".")
                      value = `${integerPart}.${decimalPart.slice(0, 2)}`
                    }

                    // 限制不能大于100
                    if (value && parseFloat(value) > 100) {
                      value = "100"
                    }

                    setGoodsWarn(value)
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default Index
