import React, { useEffect, useState, useRef } from "react"
import {
  Card,
  Button,
  Input,
  Table,
  Modal,
  message,
  Select,
  Cascader,
  Tooltip,
  TreeSelect,
} from "antd"
import * as ShopmanageDevice from "../../services/storemanage"
import { useHistory } from "react-router-dom"
import { QuestionCircleOutlined } from "@ant-design/icons"
import PubInput from "../../../components/PubInput"
import styles from "./Index.module.css"

const { Option } = Select

function Index() {
  let history = useHistory()
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [loading, setLoading] = useState(false) // 列表Loading
  const [PullList, setPullList] = useState([]) // 门店列表
  const [districtAuthList, setDistrictAuthList] = useState([]) // 区域权限列表
  const [dataType, setDataType] = useState(undefined) // 数据类型（0：全部数据，1：按门店，2：按区域）

  //
  const [tableList, setTableList] = useState([]) // 表格列表
  const [total, setTotal] = useState(0) // 列表总数

  const [ParamsInfo, setParamsInfo] = useState({
    pageNum: 1,
    pageSize: 20,
    shopId: undefined,
    status: undefined,
    cdKeyCode: undefined,
    districtId: undefined,
  })
  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })
  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])

  // 初始化
  useEffect(() => {
    getPullList() //门店下拉列表
    setTimeout(getTableList, 0) // 表格数据
    getDataAuth() // 当前项目的数据权限信息
  }, [])

  // 可视化区域
  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 当前项目的数据权限信息（涉及到了权限）
   */
  const getDataAuth = async () => {
    try {
      const res = await ShopmanageDevice.Data_Auth()
      if (!res) {
        return false
      }
      const { code, data, msg } = res
      if (code != 200) {
        message.error(msg)
        return
      }
      console.log(data)
      setDataType(data?.dataType == 0 ? "0" : data?.dataType)
      setDistrictAuthList(data?.districtAuthList)
      // setShopAuthList(data?.shopAuthList)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 门店下拉列表（涉及到了权限）
   */
  const getPullList = async () => {
    try {
      const params = { pageNum: 1, pageSize: 9999999 }
      const { code, data, msg } = await ShopmanageDevice.Pull_Auth_list(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setPullList(data.list || [])
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 表格列表接口
   */
  const getTableList = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      const params = {
        ...ParamsInfoRef,
      }
      setLoading(true)
      const { code, data, msg } = await ShopmanageDevice.GoodPluginList(params)
      if (code != 200) {
        message.error(msg)
        setLoading(false)
        return
      }
      setLoading(false)
      setTableList(data?.list)
      setTotal(data?.total)
    } catch (error) {
      console.log(error)
      setLoading(false)
      setTableList([])
    }
  }

  /**
   * 查询
   */
  const handlSearch = async () => {
    try {
      //页码为1
      setParamsInfo({ ...ParamsInfo, pageNum: 1 })
      setTimeout(getTableList, 0)
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 重置
   */
  const handClearn = async () => {
    try {
      setParamsInfo({
        pageNum: 1,
        pageSize: 20,
        shopId: undefined,
        status: undefined,
        cdKeyCode: undefined,
        districtId: undefined,
      })
      setTimeout(getTableList, 0)
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 分页
   */
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: total,
      onChange: (current) => {
        setParamsInfo({
          ...ParamsInfo,
          pageNum: current,
        })
        setTimeout(getTableList, 0)
      },
    }
    return paginationProps
  }

  const columns = [
    {
      title: "设备编号",
      dataIndex: "pluginCode",
      key: "pluginCode",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "门店名称",
      dataIndex: "shopName",
      key: "shopName",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "对应收银口",
      dataIndex: "cashRegister",
      key: "cashRegister",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      ellipsis: { showTitle: true },
      width: 200,
      render: (info) => {
        return (
          <div
            style={{
              color:
                info == 1
                  ? "#39BE87 "
                  : info == 2
                  ? "#E6A23C"
                  : info == 3
                  ? "#FF4D4F"
                  : "",
            }}
          >
            {info == 1
              ? "在线"
              : info == 2
              ? "已解绑"
              : info == 3
              ? "离线"
              : ""}
          </div>
        )
      },
    },
    {
      title: "丢失商品关联激活码(标签秤)",
      dataIndex: "cdKeyCode",
      key: "cdKeyCode",
      ellipsis: { showTitle: true },
      width: 230,
    },
    {
      title: "扫码枪",
      dataIndex: "barcodeScannerNum",
      key: "barcodeScannerNum",
      ellipsis: { showTitle: true },
      width: 200,
      render: (info) => {
        return info ? info + "个" : "0"
      },
    },
    {
      title: "监听版本号",
      dataIndex: "version",
      key: "version",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "操作系统",
      dataIndex: "productLine",
      key: "productLine",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "最近同步时间",
      dataIndex: "lastSyncTime",
      key: "lastSyncTime",
      ellipsis: { showTitle: true },
      width: 200,
    },
  ]

  return (
    <div>
      <Card>
        <div className={styles.shopmanage_store_head}>
          <div className={styles.shopmanage_store_head_search}>
            {dataType == 2 ? (
              <div>
                区域选择：
                <Select
                  allowClear
                  style={{ width: 200 }}
                  value={ParamsInfo?.districtId || undefined}
                  placeholder="请选择区域名称"
                  onChange={(e) => {
                    setParamsInfo({ ...ParamsInfo, districtId: e })
                  }}
                >
                  {districtAuthList &&
                    districtAuthList.map((v) => {
                      return (
                        <Option value={v.districtId} key={v.districtId}>
                          {v.pathName && v.districtName
                            ? v.pathName + "/" + v.districtName
                            : !v.pathName && v.districtName
                            ? v.districtName
                            : v.pathName && !v.districtName
                            ? v.pathName
                            : ""}
                        </Option>
                      )
                    })}
                </Select>
              </div>
            ) : null}
            <div>
              门店名称：
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                style={{ width: 200 }}
                placeholder="请选择门店"
                allowClear
                value={ParamsInfo.shopId || undefined}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    shopId: e || undefined,
                  })
                }}
              >
                {PullList.map((v) => {
                  return (
                    <Option value={v.shopId} key={v.shopId}>
                      {v.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>
            <div>
              状态：
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                style={{ width: 200 }}
                placeholder="请选择状态"
                allowClear
                value={ParamsInfo.status || undefined}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    status: e || undefined,
                  })
                }}
              >
                <Option value="1" key="1">
                  在线
                </Option>
                <Option value="2" key="2">
                  已解绑
                </Option>
                <Option value="3" key="3">
                  离线
                </Option>
              </Select>
            </div>
            <div>
              激活码：
              <Input
                placeholder="请输入激活码"
                style={{ width: "200px" }}
                value={ParamsInfo?.cdKeyCode}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    cdKeyCode: e.target.value || undefined,
                  })
                }}
              />
            </div>
          </div>
          <div className={styles.shopmanage_store_head_btn}>
            <Button type="primary" onClick={handlSearch}>
              查询
            </Button>
            <Button onClick={handClearn}>重置</Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: "16px" }}>
        <div>
          <Table
            columns={columns}
            pagination={onTableChange()}
            dataSource={tableList}
            rowKey=""
            loading={loading}
            scroll={{ x: "max-content", y: `${WinHeight - 370}px` }}
          />
        </div>
      </Card>
    </div>
  )
}

export default Index
